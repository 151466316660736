import { createFeatureSelector, createSelector } from '@ngrx/store';

import { coreReducerKey, InitialState } from '../reducers/core';

export const coreState = createFeatureSelector(coreReducerKey);

export const selectCore = createSelector(coreState, (state: InitialState) => state);

export const selectCoreSettings = createSelector(
  coreState,
  (state: InitialState) => state.settings,
);

export const selectProfile = createSelector(coreState, (state: InitialState) => state.profile);

export const selectSupplierConfig = createSelector(
  coreState,
  (state: InitialState) => state.profile.supplier.config,
);

export const authenticated = createSelector(
  coreState,
  (state: InitialState) => state.settings.authenticated,
);

export const firstLogin = createSelector(
  coreState,
  (state: InitialState) => state.settings.firstLogin,
);

export const showNavBar = createSelector(
  coreState,
  (state: InitialState) => state.settings.showNavBar,
);

export const selectDevice = createSelector(
  coreState,
  (state: InitialState) => state.settings.device,
);
