import { createAction, props } from '@ngrx/store';
import { WarehouseDefaultsData } from '@profile/models/profile';

export const INIT_APPLICATION = 'CORE/INIT_APPLICATION';
export const NOT_AUTHENTICATED = 'CORE/NOT_AUTHENTICATED';
export const SHOW_NAV_BAR = 'GLOBAL/SHOW_NAV_BAR';
export const RESET_FIRST_LOGIN = 'GLOBAL/RESET_FIRST_LOGIN';
export const ADD_PROFILE = 'CORE/ADD_PROFILE';
export const ADD_CREDITS = 'CORE/ADD_CREDITS';
export const ADD_PAYMENTS = 'CORE/ADD_PAYMENTS';
export const ADD_PROCESSES = 'CORE/ADD_PROCESSES';
export const ADD_WAREHOUSES = 'CORE/ADD_WAREHOUSES';
export const UPDATE_WAREHOUSE_DEFAULTS = 'CORE/UPDATE_WAREHOUSE_DEFAULTS';
export const ADD_TRANSACTIONS = 'CORE/ADD_TRANSACTIONS';
export const GET_TRANSACTIONS = 'CORE/GET_TRANSACTIONS';
export const UPDATE_STORE_PROFILE = 'CORE/UPDATE_STORE_PROFILE';
export const UPDATE_STORE_WAREHOUSES = 'CORE/UPDATE_STORE_WAREHOUSES';
export const ADD_DEVICE = 'GLOBAL/ADD_DEVICE';

export const initApplication = createAction(INIT_APPLICATION);
export const addProfile = createAction(
  ADD_PROFILE,
  props<{ profile; payments; credits; processes; warehouses }>(),
);
export const updateStoreProfile = createAction(UPDATE_STORE_PROFILE, props<{ profile }>());
export const updateStoreWarehouses = createAction(UPDATE_STORE_WAREHOUSES, props<{ warehouses }>());
export const addTransactions = createAction(ADD_TRANSACTIONS, props<{ transactions }>());
export const addDevice = createAction(ADD_DEVICE, props<{ device }>());
export const showNavBar = createAction(SHOW_NAV_BAR);
export const resetFirstLogin = createAction(RESET_FIRST_LOGIN);
export const updateWarehousesDefaults = createAction(
  UPDATE_WAREHOUSE_DEFAULTS,
  props<{ warehouseDefaultsData: WarehouseDefaultsData }>(),
);

export const actions = {
  initApplication,
  addProfile,
  addTransactions,
  addDevice,
  showNavBar,
  resetFirstLogin,
  updateWarehousesDefaults,
  updateStoreProfile,
  updateStoreWarehouses,
};
