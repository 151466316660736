import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { addDevice, initApplication } from './core/actions/core';
import { refreshLogin, logout } from './core/actions/globals';
import { InitialState as InitialCoreState } from './core/reducers/core';

import { selectCore, selectProfile, authenticated, showNavBar } from './core/selectors/core';

import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment as env } from '../environments/environment';
import { AnalyticsService } from '@core/services/common/analytics.service';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title: 'mypargo';

  authenticated$: Observable<boolean>;
  showNavBar$: Observable<boolean>;
  core$: Observable<InitialCoreState>;
  profile$: Observable<InitialCoreState['profile']>;

  constructor(
    private deviceService: DeviceDetectorService,
    private store: Store<any>,
    public router: Router,
    private analyticsService: AnalyticsService,
  ) {
    this.core$ = this.store.select(selectCore);
    this.profile$ = this.store.select(selectProfile);
    this.authenticated$ = this.store.select(authenticated);
    this.showNavBar$ = this.store.select(showNavBar);

    /** Bootstrap Google Analytics script and event to router if gaTrackingID is set */
    if (env.gaTrackingID) {
      this.addGAScript();
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          gtag('event', 'page_view', {
            page_path: event.urlAfterRedirects,
          });
        });
    }
  }

  /** Allow Adding Google Analytics Script Dynamically */
  addGAScript() {
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + env.gaTrackingID;
    document.head.prepend(gtagScript);
    /** Disable automatic page view hit to fix duplicate page view count  **/
    gtag('config', env.gaTrackingID, { send_page_view: false });
  }

  setDevice() {
    const device = {
      info: this.deviceService.getDeviceInfo(),
      mobile: this.deviceService.isMobile(),
      tablet: this.deviceService.isTablet(),
      desktop: this.deviceService.isDesktop(),
    };
    this.store.dispatch(addDevice({ device }));
  }

  ngOnInit() {
    this.setDevice();

    let routeValidForInit = true;
    const routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('auth/confirm')) {
          // prevents auth and app initialization happening on confirmation page
          routeValidForInit = false;
        } else {
          this.authenticated$.subscribe((userAuthenticated) => {
            if (userAuthenticated === true && routeValidForInit) {
              this.store.dispatch(initApplication());
            }
            routeValidForInit = true;
          });
          if (routeValidForInit) {
            this.store.dispatch(refreshLogin());
          }

          routerSub.unsubscribe();
        }
      }
    });
    this.analyticsService.init();
  }

  handleLogout(e) {
    this.store.dispatch(logout());
  }
}
