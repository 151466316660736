import { createReducer, on } from '@ngrx/store';

import { actions as globalActions } from '../actions/globals';

import { actions } from '../actions/core';
import { DeviceInfo } from 'ngx-device-detector';
import { updateWarehousesData } from '@core/utils';

export interface InitialState {
  profile: any;
  credits: number | undefined;
  payments: object;
  proceses: object[];
  warehouses: object[];
  transactions: object[];
  settings: {
    authenticated: boolean;
    firstLogin: boolean;
    showNavBar: boolean;
    device: {
      info: DeviceInfo;
      mobile: boolean;
      tablet: boolean;
      desktop: boolean;
    };
    processes: string[];
    hasPayments: boolean;
    hasCredits: boolean;
    initialized: boolean;
  };
}

export const initialState: any = {
  profile: undefined,
  credits: undefined,
  payments: undefined,
  proceses: undefined,
  warehouses: undefined,
  transactions: undefined,
  settings: {
    authenticated: false,
    firstLogin: false,
    showNavBar: false,
    device: undefined,
    processes: undefined,
    hasCredits: undefined,
    initialized: false,
  },
};

export const coreReducerKey = 'core';

export const setPayments = (payments, processes) => {
  Object.keys(payments).forEach((processKey) => {
    if (payments[processKey] && processes.includes(processKey)) {
      const regionKeys = Object.keys(payments[processKey]);

      if (regionKeys && !regionKeys.includes('main')) {
        const mainRegion = {
          5: payments[processKey]['5'],
          10: payments[processKey]['10'],
          15: payments[processKey]['15'],
        };

        payments[processKey] = {};
        payments[processKey].main = mainRegion;
      }
    } else {
      payments[processKey] = undefined;
      delete payments[processKey];
    }
  });

  return payments;
};

const coreReducer = createReducer(
  initialState,
  on(globalActions.loginSuccess, (state: InitialState) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        authenticated: true,
      },
    };
  }),
  on(globalActions.signUpAuth, (state: InitialState) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        authenticated: true,
        firstLogin: true,
      },
    };
  }),
  on(globalActions.logoutSuccess, (state: InitialState) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        authenticated: false,
        showNavBar: false,
      },
    };
  }),
  on(globalActions.addCredits, (state: InitialState, { credits }) => {
    return {
      ...state,
      credits,
    };
  }),
  on(actions.showNavBar, (state: InitialState) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        showNavBar: true,
      },
    };
  }),
  on(
    actions.addProfile,
    (state: InitialState, { profile, credits, payments, processes, warehouses }) => {
      const mappedProcesses = processes && processes.map((pc) => pc.name);
      const hasPayments =
        payments &&
        Object.keys(payments).filter(
          (key) =>
            payments[key] !== null &&
            mappedProcesses &&
            mappedProcesses.length &&
            mappedProcesses.includes(key),
        ).length > 0;
      const hasCredits = hasPayments && credits !== null && credits !== undefined;

      const updatedPayments = setPayments(payments, mappedProcesses);

      return {
        ...state,
        profile,
        payments,
        credits,
        processes,
        warehouses,
        settings: {
          ...state.settings,
          hasCredits: hasCredits,
          hasPayments: updatedPayments,
          processes: mappedProcesses,
          initialized: true,
        },
      };
    },
  ),
  on(actions.addTransactions, (state: InitialState, { transactions }) => {
    return {
      ...state,
      transactions,
    };
  }),
  on(actions.addDevice, (state: InitialState, { device }) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        device,
      },
    };
  }),
  on(actions.resetFirstLogin, (state: InitialState) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        firstLogin: false,
      },
    };
  }),
  on(actions.updateWarehousesDefaults, (state: InitialState, { warehouseDefaultsData }) => {
    const warehouses = updateWarehousesData([...state.warehouses], warehouseDefaultsData);

    return {
      ...state,
      warehouses,
    };
  }),
  on(actions.updateStoreProfile, (state: InitialState, { profile }) => {
    return {
      ...state,
      profile,
    };
  }),
  on(actions.updateStoreWarehouses, (state: InitialState, { warehouses }) => {
    return {
      ...state,
      warehouses,
    };
  }),
);

export default function reducer(state, action) {
  return coreReducer(state, action);
}
